import drop from 'lodash.drop';
import uniq from 'lodash.uniq';

import medusa from '@/assets/js/observer';

export default {
  data() {
    return {
      requests: [],
      request: null,
      type: null,
      items: [],
      placeholder: 'Loading',
      offset: parseInt(this.data.offset, 10) || 0,
      counter: 1,
      showLoadMore: true,
      skeletonItems: this.data.infinite_scroll
        ? parseInt(this.data.posts_per_page, 10)
        : this.data.archive_type === 'posts'
          ? this.data.posts.length
          : parseInt(this.data.max_posts, 10) || 4,
      id: null,
    };
  },
  created() {
    if (this.data.infinite_scroll) {
      this.id = Buffer.from(JSON.stringify(this.data), 'utf8').toString('base64');
    }
  },
  mounted() {
    this.setRequest();
    this.loadItems();

    if (this.data.infinite_scroll) {
      this.$nextTick(this.setInfiniteScroll);
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes(`infinite-scroll-${this.id}`)) {
      medusa.ref.removeTarget(`infinite-scroll-${this.id}`);
    }
  },
  methods: {
    async loadItems() {
      const items = [];
      const promises = [];

      this.requests.forEach((request) => {
        promises.push(this.$store.dispatch('getItems', request));
      });

      const responses = await Promise.all(promises);
      responses.map((data) => items.push(...data));

      if (items && items.length > 0) {
        this.items = [...this.items, ...items];
      }

      if (this.type === 'posts') {
        const ids = this.data.posts.map((item) => item.ID);
        this.items.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
      }

      // this.$root.$children[0].lazyObserve(this.$el);

      return [...items];
    },
    setRequest() {
      let postType = null;
      const categories = {};
      let include = [];
      const { exclude } = this.data;
      let slugArray = [];

      this.type = this.data.archive_type;

      if (this.type === 'archive') {
        const { archive, archive_category, tags } = this.data;
        postType = archive === 'post' || archive === 'page' ? `${archive}s` : archive;
        if (archive_category) {
          // const taxonomy = archive_category.taxonomy === 'category' ? 'categories' : archive_category.taxonomy;
          const { taxonomy } = archive_category;
          categories[taxonomy] = archive_category.term_id;
        }
        if (tags) {
          categories.tags = tags;
        }
        include = this.data[postType] ? this.data[postType] : [];
      } else if (this.type === 'taxonomy') {
        postType = 'categories';
        include = this.data[postType].map((item) => item.term_id);
      }

      const slug = slugArray.length > 0
        ? this.data.infinite_scroll
          ? drop(slugArray, this.offset)
          : slugArray
        : null;
      const offset = this.type !== 'posts' ? this.offset : 0;

      if (this.type !== 'posts') {
        this.requests[0] = {
          type: postType,
          params: {
            ...categories,
            slug,
            include: include.length > 0 ? include : null,
            exclude: exclude && exclude.length > 0 ? exclude : null,
            per_page: this.data.infinite_scroll
              ? parseInt(this.data.posts_per_page, 10) || 12
              : parseInt(this.data.max_posts, 10) || 100,
            offset,
            categories_exclude: this.data.exclude_cat ? this.data.exclude_cat : null,
            order: this.data.orderby && this.data.orderby === 'menu_order' ? 'asc' : null,
            orderby:
              this.data.orderby && this.data.orderby !== 'default'
                ? this.data.orderby
                : slugArray.length > 0
                  ? 'include_slugs'
                  : this.type === 'taxonomy'
                    ? 'id'
                    : null,
            lang: this.$store.state.lang,
            _fields:
              'id,slug,title,name,link,permalink,gds_featured_image,featured_media,gds_taxonomies,date_format,tags,price_html,type,stock_status,purchasable,reading_time,acf.release,acf.template,acf.thumb_color,button_text',
          },
        };
      } else {
        const postTypes = uniq(this.data[this.type].map((el) => el.post_type));
        postTypes.forEach((currentPostType) => {
          postType = currentPostType === 'post' ? `${currentPostType}s` : currentPostType;
          slugArray = currentPostType !== 'product'
            ? this.data[this.type]
              .filter((item) => item.post_type === currentPostType)
              .map((item) => item.post_name)
            : [];
          include = currentPostType === 'product'
            ? this.data[this.type]
              .filter((item) => item.post_type === currentPostType)
              .map((item) => item.ID)
            : [];

          this.requests.push({
            type: postType,
            params: {
              slug: slugArray.length > 0 ? slugArray : null,
              include: include.length > 0 ? include : null,
              exclude: exclude && exclude.length > 0 ? exclude : null,
              per_page: this.data.infinite_scroll
                ? parseInt(this.data.posts_per_page, 10) || 12
                : parseInt(this.data.max_posts, 10) || 100,
              offset: 0,
              category__not_in: this.data.exclude_cat ? this.data.exclude_cat : null,
              order: this.data.orderby && this.data.orderby === 'menu_order' ? 'asc' : null,
              orderby:
                this.data.orderby && this.data.orderby !== 'default'
                  ? this.data.orderby
                  : slugArray.length > 0
                    ? 'include_slugs'
                    : this.type === 'taxonomy'
                      ? 'id'
                      : null,
              lang: this.$store.state.lang,
              _fields:
                'id,slug,title,name,link,permalink,gds_featured_image,featured_media,gds_taxonomies,date_format,tags,price_html,type,stock_status,purchasable,reading_time,acf.release,acf.template,acf.thumb_color,button_text',
            },
          });
        });
      }
    },
    setInfiniteScroll() {
      medusa.ref.addTarget({
        id: `infinite-scroll-${this.id}`,
        threshold: 0.0,
        nodes: [],
        mode: 'default',
        callback: this.setOffset,
        autoremove: false,
        offsets: `0px 0px ${window.innerHeight}px 0px`,
      });

      medusa.ref.pushToTarget(`infinite-scroll-${this.id}`, this.$el.querySelector('.infinite'));
    },
    setOffset(entry) {
      if (entry.isIntersecting && this.counter < 5) {
        this.requestOffset();
      }
    },
    requestOffset() {
      if (
        this.type === 'posts'
        && this.offset + (parseInt(this.data.posts_per_page, 10) || 12) >= this.data[this.type].length
      ) {
        medusa.ref.removeTarget(`infinite-scroll-${this.id}`);
        return;
      }

      this.showLoadMore = false;

      this.offset += parseInt(this.data.posts_per_page, 10) || 12;
      this.setRequest();

      this.loadItems().then((newItems) => {
        this.counter += 1;
        this.showLoadMore = true;

        if (newItems.length === 0) {
          medusa.ref.removeTarget(`infinite-scroll-${this.id}`);
        } else if (window.pageYOffset + window.innerHeight === document.body.offsetHeight) {
          this.requestOffset();
        }
      });
    },
  },
};
