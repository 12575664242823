/* eslint-disable no-undef */

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

// import Page from '@/views/Page.vue';
// import Search from '@/views/Search.vue';
// import Category from '@/views/Category.vue';
import SwitchComponent from '@/views/SwitchComponent.vue';

import Single from '@/views/Single.vue';
import NotFound from '@/views/404.vue';
import Preview from '@/views/Preview.vue';
import wcRoutes from './wc';

const importedRoutes = wcRoutes;

const singles = new Map();

// HINT: Add custom Post Type - 2
singles.set('post', Single);
singles.set('publishing', Single);

Vue.use(VueRouter);

const { show_on_front, page_on_front } = __VUE_WORDPRESS__.routing;

const {
  postTypes, categoryTypes, categories, tags,
} = __VUE_WORDPRESS__.state;

const { name: siteTitle, description } = __VUE_WORDPRESS__.state.site;

store.commit('ADD_LANGUAGES', ['default']);

const baseRoutes = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      breadcrumbs: {
        name: '404',
        link: '/404',
      },
    },
  },
  {
    path: '/',
    name: 'Index',
    component: SwitchComponent,
    meta: {
      slug: page_on_front,
      type: show_on_front ? 'pages' : 'posts',
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: SwitchComponent,
    meta: {
      component: 'search',
      breadcrumbs: {
        name: 'Search',
        link: '/search',
      },
    },
  },
  {
    path: '/:slug',
    name: 'Page',
    component: SwitchComponent,
  },
  {
    path: '/preview/:type/:id',
    name: 'Preview',
    component: Preview,
    meta: {
      breadcrumbs: {
        name: 'Preview',
        link: '/',
      },
    },
  },
];

const routes = importedRoutes.concat(baseRoutes);

Object.keys(postTypes).forEach((key) => {
  if (key !== 'page' && key !== 'product') {
    routes.push({
      path: `/${postTypes[key].rewrite.slug}/:slug`,
      name: `${key}-${singles.get(key).name}`,
      components: {
        default: SwitchComponent,
        overlay: singles.get(key),
      },
      props: {
        default: {
          slug: key === 'post' ? 'magazine' : `${key}`,
          type: 'pages',
        },
        overlay: { type: postTypes[key].rest_base },
      },
      meta: {
        type: postTypes[key].rest_base,
        overlay: true,
      },
    });
  }
});

Object.keys(categoryTypes).forEach((key) => {
  if (key === 'post_tag') {
    routes.push({
      path: '/publishing/tag/:slug',
      name: `${key}-publishing-Category`,
      component: SwitchComponent,
      meta: {
        key,
        component: 'category',
        category: categoryTypes[key].rest_base,
        postType: 'publishing',
        parent: 'publishing',
      },
    });
  }
  routes.push({
    path: `/${categoryTypes[key].rewrite.slug}/:slug`,
    name: `${key}-Category`,
    component: SwitchComponent,
    meta: {
      key,
      component: 'category',
      category:
        postTypes[categoryTypes[key].object_type[0]].rest_base === 'product'
          ? 'category'
          : categoryTypes[key].rest_base,
      postType: postTypes[categoryTypes[key].object_type[0]].rest_base,
      parent: categoryTypes[key].rewrite.slug.match('([a-zA-Z])([^/]*)')[0],
    },
  });
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (
      store.state.goBack
      && (from.name === 'post-Single'
        || from.name === 'SingleProduct'
        || from.name === 'publishing-Single')
      && to.name !== 'post-Single'
      && to.name !== 'SingleProduct'
      && to.name !== 'publishing-Single'
    ) return false;
    if (
      store.state.goBack
      && (from.name === 'post-Single'
        || from.name === 'SingleProduct'
        || from.name === 'publishing-Single')
      && (to.name === 'post-Single' || to.name === 'SingleProduct' || to.name === 'publishing-Single')
    ) return false;
    if (
      store.state.goBack
      && (from.name === 'post-Single'
        || from.name === 'SingleProduct'
        || from.name === 'publishing-Single')
      && (to.name === 'SingleProduct' || to.name === 'post-Single' || to.name === 'publishing-Single')
    ) return false;
    if (
      store.state.goBack
      && (from.name === 'post-Single'
        || from.name === 'SingleProduct'
        || from.name === 'publishing-Single')
      && (to.name === 'publishing-Single' || to.name === 'SingleProduct' || to.name === 'post-Single')
    ) return false;
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  to.meta.avoidTransition = to.query.switch;

  if (store.state.wc.user && to.query.wcs_redirect && to.query.wcs_redirect_id) {
    next({
      name: 'PayOrder',
      params: {
        orderId: to.query.wcs_redirect_id,
      },
    });
  }

  if (store.state.goBack) {
    store.commit('SET_GO_BACK', false);
  }
  if (to.meta.fetchCountry) {
    await store.dispatch('getCountries');
  }

  if (from.path !== to.path) {
    store.commit('SET_CURRENT_PAGE', null);
    store.commit('SET_CURRENT_SINGLE', null);
  }

  if (to.meta.customView || to.name === '404' || to.name === 'Search' || to.name === 'Preview') {
    document.title = `${to.name} — ${siteTitle}`;
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    if (to.meta.breadcrumbs) {
      store.commit('SET_CURRENT_PAGE', to.meta.breadcrumbs);
    }

    if (store.state.menu) {
      store.commit('SET_MENU', false);
    }
    store.commit('SET_OVERLAY', false);
    next();
    return;
  }

  const slug = to.meta.slug ? to.meta.slug : to.params.slug;
  const lang = 'default';

  store.commit('SET_LANG', lang);

  const request = {
    type: to.meta.type || 'pages',
    slug,
    lang,
    fromArchive: false,
  };

  const promises = [];

  let categoryName = null;

  if (to.name && to.name.includes('Category')) {
    let parent = null;
    let id = null;
    let name = null;
    const { key } = to.meta;
    if (key === 'post_tag') {
      parent = 'tags';
      let tag = tags.default[to.params.slug];
      if (!tag) {
        tag = await store.dispatch('getSingleBySlug', {
          type: 'tags',
          slug: to.params.slug,
          lang: 'default',
        });

        if (!tag) {
          router.push({ name: '404' });
          return;
        }
      }
      id = tag.id;
      name = tag.name;
    } else {
      parent = to.meta.parent;
      let cat = categories[parent].default[to.params.slug];
      if (!cat) {
        cat = await store.dispatch('getSingleBySlug', {
          type: to.meta.category,
          slug: to.params.slug,
          lang: 'default',
          category: parent,
        });

        if (!cat) {
          router.push({ name: '404' });
          return;
        }
      }
      id = cat.id;
      name = cat.name;
    }

    categoryName = name;
    const params = {};
    params[to.meta.category] = id;
    params.per_page = parent === 'shop' ? 20 : 25;
    params.offset = 0;
    params.lang = 'default';
    params.include = null;
    params.exclude = null;
    params.order = 'asc';
    params.orderby = 'date';
    params.slug = null;
    // eslint-disable-next-line no-underscore-dangle
    params._fields = 'id,slug,title,name,link,permalink,gds_featured_image,featured_media,gds_taxonomies,date_format,tags,price_html,type,stock_status,purchasable,reading_time,acf.release,acf.template,acf.thumb_color,button_text';

    promises.push(store.dispatch('getItems', { type: to.meta.postType, params }));
  } else {
    promises.push(store.dispatch('getSingleBySlug', request));
  }

  if (to.name === 'post-Single' && (!from.name || from.meta.overlay)) {
    promises.push(
      store.dispatch('getSingleBySlug', {
        slug: 'magazine',
        type: 'pages',
        lang,
        fromArchive: false,
      }),
    );
  } else if (to.name === 'publishing-Single' && (!from.name || from.meta.overlay)) {
    promises.push(
      store.dispatch('getSingleBySlug', {
        slug: 'publishing',
        type: 'pages',
        lang,
        fromArchive: false,
      }),
    );
  } else if (to.name === 'SingleProduct' && (!from.name || from.meta.overlay)) {
    promises.push(
      store.dispatch('getSingleBySlug', {
        slug: 'shop',
        type: 'pages',
        lang,
        fromArchive: false,
      }),
    );
  }

  if (store.getters.isSubscription(slug)) {
    store.getters.magazineSubscription.products.forEach((p) => {
      if (p.post_name !== slug) {
        promises.push(
          store.dispatch('getSingleBySlug', {
            type: to.meta.type || 'pages',
            slug: p.post_name,
            lang,
            fromArchive: false,
          }),
        );
      }
    });
  }

  Promise.all(promises).then(async (res) => {
    if (!document.body.classList.contains('app-loaded')) {
      document.body.classList.add('app-loaded');
    }

    const page = res[0];
    if (page.type === 'page') {
      await store.dispatch('getItems', {
        type: 'pages',
        params: {
          parent: page.id,
          lang: 'default',
          orderby: 'date',
          order: 'asc',
        },
      });
    }

    store.commit(
      'SET_OVERLAY',
      to.name === 'post-Single' || to.name === 'SingleProduct' || to.name === 'publishing-Single',
    );

    if (page && slug) {
      if (to.path === '/') {
        document.title = `${siteTitle} — ${description}`;
      } else {
        // Avoid HTML entities in title
        const p = document.createElement('p');
        p.innerHTML = to.name.includes('Category')
          ? categoryName
          : page.title
            ? page.title.rendered
            : page.name;
        document.title = `${p.innerText} — ${siteTitle}`;
      }
      if (store.state.menu) {
        store.commit('SET_MENU', false);
      }
      next();
    } else {
      router.push({ name: '404' });
    }
  });
});

export default router;
