<template>
  <div :class="['thumb', 'thumb--product', `thumb--${parsedThumb}`, `thumb--${color}`]">
    <router-link
      :to="data.link ? $relativeUrl(data.link) : $relativeUrl(data.permalink)"
      class="link"
    />
    <div class="media">
      <Media
        v-if="(isSubscription(data.slug) && magazineSubscription.thumb) || data.gds_featured_image"
        :data="{
          type: 'image',
          image: isSubscription(data.slug) ? magazineSubscription.thumb : data.gds_featured_image,
          ratio: false,
          lazyload: true,
          double_size: true,
          object_fit: 'cover',
        }"
      />
    </div>
    <div class="abstract">
      <Spacer
        top="xs"
        bottom="xxs"
        x="s"
        class="abstract-wrapper"
      >
        <Flex
          justify="space-between"
          class="abstract-flex"
        >
          <Title
            :data="{
              value: isSubscription(data.slug)
                ? magazineSubscription.title
                : data.taxonomy || data.name
                  ? data.name
                  : data.title.rendered,
              size: $mq.isMobile ? 'm' : 's',
            }"
            class="title"
          />

          <Price
            v-if="!isSubscription(data.slug)"
            :product="data"
            :class="[$mq.isMobile ? 'typo--m' : 'typo--s']"
          />
        </Flex>
      </Spacer>
    </div>
    <div class="cta-wrapper">
      <Cta
        v-if="isSubscription(data.slug)"
        theme="button-light"
        :data="{
          title: $labels.subscribe,
          url: data.link || data.permalink,
        }"
      />

      <Cta
        v-else-if="!hasVariations"
        theme="button-light"
        :data="{
          title: labelCta,
          url: false,
          fn: addToCart,
        }"
        :disabled="!addableToCart"
      />

      <Cta
        v-else
        theme="button-light"
        :data="{
          title: $labels.select_option,
          url: data.link || data.permalink,
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { addableToCart, isOos } from '@/assets/js/utils-wc';

import Media from '@/components/blocks/media';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';
import Price from '@/components/wc/price.vue';
// import Availability from '@/components/wc/availability.vue';

export default {
  name: 'Thumb',
  components: {
    Media,
    Title,
    Cta,
    Price,
    // Availability,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'link',
    },
    thumb: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      labelCta: this.$labels.add_to_cart,
    };
  },
  computed: {
    ...mapGetters(['isSubscription', 'magazineSubscription']),
    parsedThumb() {
      return this.thumb ? this.thumb : this.data.thumb;
    },
    isOos() {
      return isOos(this.data);
    },
    hasVariations() {
      return this.data.type ? this.data.type.startsWith('variable') : false;
    },
    addableToCart() {
      if (!addableToCart(this.data) || isOos(this.data)) {
        return false;
      }
      return true;
    },
    color() {
      return this.data.acf.thumb_color;
    },
  },
  async created() {
    if (isOos(this.data)) {
      this.labelCta = this.$labels.out_of_stock;
    }

    if (this.data.button_text) {
      this.labelCta = this.data.button_text;
    }
  },
  methods: {
    addToCart() {
      this.labelCta = this.$labels.added;
      this.$store
        .dispatch(
          'addToCart',
          Object.assign(this.data, {
            id: String(this.data.id),
            quantity: 1,
            quantityDelta: 1,
          }),
        )
        .then(() => {
          this.labelCta = this.$labels.add_to_cart;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  position: relative;
  display: block;
  @include aspect-ratio(3.25, 4);

  .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
  }

  .cta-wrapper {
    visibility: hidden;
    opacity: 0;
  }

  @include mq($and: $hover) {
    &:hover {
      .cta-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .cta-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    height: auto;
    z-index: 3;
  }

  .abstract {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: auto;
  }

  .media {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &--horizontal-xl {
    @include aspect-ratio(4, 3);

    .abstract {
      @include mq(m) {
        position: sticky;
        margin-bottom: var(--bar-height);

        top: calc(var(--bar-height) * 2);
        transition: top 0.4s $ease-custom;

        .header-scrolling & {
          top: calc(var(--bar-height) * 1);
        }

        .app--home & {
          top: calc(var(--bar-height) * 1);
        }

        .app--home.header-scrolling & {
          top: 0;
        }
      }
    }

    .abstract-flex {
      & > * {
        flex: 0 0 50%;
      }
    }
  }

  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1 1 0;

    margin-right: var(--spacer-l);
  }

  &--white {
    color: var(--white);
  }
}
</style>
